import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <image
          width="60"
          height="60"
          x="13"
          y="15"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE0AAABqCAMAAADOfiwMAAAABGdBTUEAALGPC/xhBQAAACBjSFJN AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABiVBMVEUAAABk/9tk/9lk/9tk /9lk/9lk/9lk/9pk/9hm/9lm/8xk/9lk/9pk/9pk/9tk/9pm/91k/9pl/9pm/+Zk/9pk/9pi/9lk /9pk/9pk/9lk/9pk/9ll/9lg/99e/9di/9pj/9tl/9tl/9pj/9pk/9pk/9pi/9hl/9pl/9pk/9pl /9ln/91k/9po/9xl/9pk/9pk/9pk/9pk/9pk/9pl/9tj/9ll/9tk/9pk/9pk/9tk/9pk/9pk/9pj /9oA//9k/9pk/9pk/9hl/9pj/9tl/9tk/9pl/9lk/9tj/9Vk/9pj/9pt/9tk/9pj/95h/9tk/9hk /9tk/9lk/9pl/9dk/9pq/9Vk/9po/9lk/9pk/95k/9pk/9pj/9pk/9pi/9hj/9xk/9tk/9pl/9pg /99l/9pm/9lm/9pl/9lk/9pV//9k/9tk/9tk/9tk/9pk/9pk/9pm/9tj/9tk/9po/9dk/9lj/9pk /9lk/9pj/9xl/9pk/9pj/9pk/9pk/9lm/9dj/9pk/9pk/9r///8JiJ0CAAAAgXRSTlMAcIB/enNr WUIoBeD72KFmHsloCu3XL/f9Xvo9UQgTIjFWi8P+5A1MifWOJccWuX3T1EXBW2yvzOHZzePP3wH2 vy6dcWrKNU8S24MHwh8qIYzF7ibvDOYbhBemkZ/cGiyF5dUQtDw3WM4Dm1Q4lrP5MpqyIJyxsPwk MKSnrqktrK0cE9KBAAAAAWJLR0SCi7P/RAAAAAd0SU1FB+UBDRUKJwBf7YYAAAIjSURBVGje7dfp UxNBEAXw5hICiCgkwaAmXFESBAVFkMuoYAgaUVBBEfDAC7w4PFGc/9wqq6ZnS4TprXkfrGLe1+36 1dvayfaGyMfHx+f/S0np7pSVVxyqrIpUh9dq1J6pPVx3pB6m/cnRYw1ATanGCvkt2zWlojGkpuKV SE01HUdqKtEcTjtx0uRUMtXS2tYe5DrSobTTuy4lz3QGuIyjRpTtCjyJs64aVXcbrsdZo/Q51s67 a9RryiXdNepj7QJAuxjmqVq1CGv9AI340F1CaAN6YhChXdYTQwhtWE+MILQhPTEM0EbH9MQVgJbj E3IVoF1j7TpAG2dtwl27wdjAqLOWn2StYMcs2tRN8+695aoV+eTKqu2nZTNxg02KPkf+uQHzt1Mt 03eCG/DujAQTbudZ2XKWadGsDJNonYV7Qsyu3W99ILXs2rhg74XolpibAmpKdc2H1x7yB37h0ULf bJB7vBha++u3sLT8xHBPnzlqRA2lCeaeC95G+2tEK+Z+y901esEbJpFz1+gll3v12l2jN8wJdoz9 27JDX2/Mu2u0yuWmARrxZogLzrBVW+NybwEavdMT7+sB2gcu9xGg0Tof4Q2AZsptAjTa0jO1thex REtxuQWARp/00OcvAM2UqwNogXIzAO0rf5N8A2jUr8eaIgAtxuW+AzTa5nJFgGbK/QBoplz7T4CW 43I7AI1+cbkYQDPlBH/JfXx8fHx8DkB+AzUe7ZpjsMLDAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIx LTAxLTEzVDIxOjEwOjM5KzAwOjAwhFl9AwAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMS0wMS0xM1Qy MToxMDozOSswMDowMPUExb8AAAAASUVORK5CYII="></image>

        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
